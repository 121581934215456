import React, { useState, useEffect } from 'react'
import Sidebar from '../SideBar/Sidebar'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Header from '../Header/Header';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const config = require('../Helpers/config.json');

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Packages = () => {
const [packagename,setpackagename]=useState("")
const [price,setprice]=useState("")
const [start,setstart]=useState("")
const [end,setend]=useState("")
const [triggers,settriggers]=useState("")
const [integration,setintegration]=useState("")
const [support,setsupport]=useState("")
const [pkgid,setpkgid]=useState("")
const [UpdateState,setUpdateState]=useState()



    const [data, setdata] = useState([])
    useEffect(() => {
        // ${config['baseUrl']}
        fetch(`${config['baseUrl']}/getAllPackages`, {
            method: "GET",
            headers: { 'content-type': 'application/json' }
        }).then(res => {
            return res.json()
        }).then(res => {
            console.log(res)
            var temp = res.data
            setdata([...temp])
        })
    }, [])


    const CreatePackage=async()=>{
        
        await fetch(`${config['baseUrl']}/createPackages`, {
            method: "POST",
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                    "name":packagename,
                    "price":price,
                    "initial_leads_limit":start,
                    "leads_limit":end,
                    "triggers_limit":triggers == "" ? 1 : triggers,
                    "integration":integration,
                    "support":support,
            })
        }).then(res => {
            return res.json()
        }).then(res => {
            console.log(res)
            
       if(res.message=="created"){
        window.location.reload(false)
       }
       else{
        alert("failed")
       }
        })
    }

    const UpdatePackage=async()=>{
        const updatedTriggers = triggers === "" ? UpdateState.triggers_limit : triggers;
        await fetch(`${config['baseUrl']}/updatePackages`, {
            method: "POST",
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                    "name":packagename==""?UpdateState.name:packagename,
                    "price":price==""?UpdateState.price:price,
                    "initial_leads_limit":start,
                    "leads_limit":end==""?UpdateState.leads_limit:end,
                    "triggers_limit": updatedTriggers,
                    "integration":integration==""?UpdateState.integration:integration,
                    "support":support==""?UpdateState.support:support,
                    "id":pkgid,
            })
        }).then(res => {
            return res.json()
        }).then(res => {
            console.log(res)
       if(res.message=="updated"){
        window.location.reload(false)
       }
       else{
        alert("failed")
       }
        })
    }

    const DeletePackage=async()=>{
        await fetch(`${config['baseUrl']}/deletePackages`, {
            method: "POST",
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                    "id":pkgid
            })
        }).then(res => {
            return res.json()
        }).then(res => {
            console.log(res)
       if(res.message=="deleted"){
        window.location.reload(false)
       }
       else{
        alert("failed")
       }
        })
    }




    // States
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const [openEdit, setOpenEdit] = useState(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);

    const [openDelete, setOpenDelete] = useState(false);
    const handleOpenDelete = () => setOpenDelete(true);
    const handleCloseDelete = () => setOpenDelete(false);
    return (
        <>
            <Header />
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-3 p-0">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-6">
                                <h1>Packages</h1>

                            </div>
                            <div className="col-md-6 text-end">
                                <Button onClick={handleOpen} variant="outlined" color="primary"> Create Package</Button>
                                {/* Create Modal */}
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <div className="container">
                                            <div className="row border-bottom mb-4">
                                                <div className="col-md-8">
                                                    <h5 className=''>Create Package</h5>
                                                </div>
                                                <div className="col-md-4 text-end">
                                                    <span onClick={handleClose} className="cross cursor">
                                                        <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M18 6 6 18" />
                                                            <path d="m6 6 12 12" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Package Name:</span>
                                                    <input onChange={(e)=>setpackagename(e.target.value)} placeholder='Package name...' type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Package Price:</span>
                                                    <input onChange={(e)=>setprice(e.target.value)} placeholder='Package Price...' type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>First month Leads:</span>
                                                    <input onChange={(e)=>setstart(e.target.value)} placeholder='First month Leads...' type="number" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Leads/month ongoing:</span>
                                                    <div className="set-create-package-div-1">
                                                         <input type="number" value={start} placeholder='Start...' readOnly className="form-control"/>  
                                                         <span>-</span>
                                                    <input onChange={(e)=>setend(e.target.value)} placeholder='End...' type="number" className="form-control" />
                                                                                             
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Package Triggers:</span>
                                                    <select onChange={(e)=>settriggers(e.target.value)} name="" id="" class="form-select">
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="0">Unlimited</option>
                                                    </select>

                                                    {/* <input onChange={(e)=>settriggers(e.target.value)} placeholder='Package Triggers...' type="text" className="form-control" /> */}
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Package Integration:</span>
                                                    <input onChange={(e)=>setintegration(e.target.value)} placeholder='Package Integration...' type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Package Support:</span>
                                                    <input onChange={(e)=>setsupport(e.target.value)} placeholder='Package Support...' type="text" className="form-control" />
                                                </div>
                                            </div>
                                            {/* <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>A new company:</span>
                                                    <input placeholder='Enter company name...' type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>A time in role:</span>
                                                    <input placeholder='Enter time in role...' type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Location:</span>
                                                    <input placeholder='Enter location...' type="text" className="form-control" />
                                                </div>
                                            </div> */}
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <Button onClick={()=>CreatePackage()} variant="outlined" color="success" className='w-100'>Create</Button>
                                                </div>
                                            </div>

                                        </div>
                                    </Box>
                                </Modal>

                                <Modal
                                    open={openEdit}
                                    onClose={handleCloseEdit}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <div className="container">
                                            <div className="row border-bottom mb-4">
                                                <div className="col-md-8">
                                                    <h5 className=''>Update Package</h5>
                                                </div>
                                                <div className="col-md-4 text-end">
                                                    <span onClick={handleCloseEdit} className="cross cursor">
                                                        <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M18 6 6 18" />
                                                            <path d="m6 6 12 12" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Package Name:</span>
                                                    <input defaultValue={UpdateState?UpdateState.name:""} onChange={(e)=>setpackagename(e.target.value)} placeholder='Package name...' type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Package Price:</span>
                                                    <input defaultValue={UpdateState?UpdateState.price:""} onChange={(e)=>setprice(e.target.value)} placeholder='Package Price...' type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>First month Leads:</span>
                                                    <input defaultValue={UpdateState?UpdateState.initial_leads_limit:""} onChange={(e)=>setstart(e.target.value)} placeholder='Package Start...' type="number" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Leads/month ongoing:</span>
                                                    <div className="set-create-package-div-1">
                                                    <input type="number" value={start} placeholder='Start...' readOnly className="form-control"/>  
                                                         <span>-</span>
                                                    <input defaultValue={UpdateState?UpdateState.leads_limit:""} onChange={(e)=>setend(e.target.value)} placeholder='End...' type="number" className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Package Triggers:</span>
                                                    <select value={triggers === "" ? (UpdateState ? UpdateState.triggers_limit : "") : triggers} onChange={(e)=>settriggers(e.target.value)} name="" id="" class="form-select">
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="0">Unlimited</option>
                                                    </select>
                                                    {/* <input defaultValue={UpdateState?UpdateState.triggers:""} onChange={(e)=>settriggers(e.target.value)} placeholder='Package Triggers...' type="text" className="form-control" /> */}
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Package Integration:</span>
                                                    <input defaultValue={UpdateState?UpdateState.integration:""} onChange={(e)=>setintegration(e.target.value)} placeholder='Package Integration...' type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Package Support:</span>
                                                    <input defaultValue={UpdateState?UpdateState.support:""} onChange={(e)=>setsupport(e.target.value)} placeholder='Package Support...' type="text" className="form-control" />
                                                </div>
                                            </div>
                                            {/* <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>A new company:</span>
                                                    <input placeholder='Enter company name...' type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>A time in role:</span>
                                                    <input placeholder='Enter time in role...' type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Location:</span>
                                                    <input placeholder='Enter location...' type="text" className="form-control" />
                                                </div>
                                            </div> */}
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <Button onClick={()=>UpdatePackage()} variant="outlined" color="success" className='w-100'>Update</Button>
                                                </div>
                                            </div>

                                        </div>
                                    </Box>
                                </Modal>

                                <Modal
                                    open={openDelete}
                                    onClose={handleCloseDelete}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <div className="container">
                                            <div className="row border-bottom mb-4">
                                                <div className="col-md-8">
                                                    <h5 className=''>Delete Package</h5>
                                                </div>
                                                <div className="col-md-4 text-end">
                                                    <span onClick={handleCloseDelete} className="cross cursor">
                                                        <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M18 6 6 18" />
                                                            <path d="m6 6 12 12" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                          <h5>Are you sure You want to delete package?</h5>
                                                </div>
                                            </div>
                                        
                                            <div className="row mb-3">
                                                <div className="col-md-6">
                                                    <Button onClick={()=>DeletePackage()} variant="outlined" color="success" className='w-100'>Delete</Button>
                                                </div>
                                                <div className="col-md-6">
                                                    <Button onClick={()=>handleCloseDelete()} variant="outlined" color="success" className='w-100'>Cancel</Button>
                                                </div>
                                            </div>

                                        </div>
                                    </Box>
                                </Modal>

                               
                            </div>
                        </div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">
                                            Package name
                                        </TableCell>
                                        <TableCell align="center">Features</TableCell>
                                        <TableCell align="center">Edit</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                            data&&data.length>0?data.map(data=>(
                                    <>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                       
                                        <TableCell>
                                        <span className="new"></span>
                                        <h4 className='text-center'>{data.name}</h4>
                                        </TableCell>
                                        <TableCell>
                                            <ul>
                                                {data.price && <li>${data.price} per month</li>}
                                                {data.initial_leads_limit &&  <li>First month Lead {data.initial_leads_limit}</li>}
                                            <li>Leads/month ongoing {data.initial_leads_limit} - {data.leads_limit}</li>
                                            <li>{data.triggers_limit == 0 ? "Unlimited" : data.triggers_limit} Triggers</li>
                                            {data.integration && <li>{data.integration}</li>}
                                            {data.support && <li>{data.support}</li>}
                                        </ul>
                                        </TableCell>
                                    

                                        <TableCell align="center">
                                            <span className="edit-btn text-warning"  onClick={()=>{
                                                handleOpenEdit()
                                                setpkgid(data.id)
                                                setUpdateState(data)
                                                setstart(data?.initial_leads_limit)
                                            }}>
                                                <svg width={17} height={17} fill="none" stroke="CurrentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 20h9" />
                                                    <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" />
                                                </svg></span>
                                        </TableCell>
                                        <TableCell align="center">
                                            <span className="delete-btn text-danger"  onClick={()=>{
                                                handleOpenDelete()
                                                setpkgid(data.id)
                                            }}>
                                                <svg width={17} height={17} fill="none" stroke="CurrentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3 6h18" />
                                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                                </svg>
                                            </span>
                                        </TableCell>


                                    </TableRow>
                                    </>


                                
                                )):"data not found"
                            }


                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>


                </div>
            </div>
        </>
    )
}

export default Packages
