import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepOrange, deepPurple } from '@mui/material/colors';
import { NavLink, Link } from 'react-router-dom';
import logo from '../../img/logo.png'
const Header = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <div className="container-fluid box-shadow pb-2 border-bottom bg-white">
                <div className="row">
                    <div className="col-md-4 mt-3">
                        <div className="header-left">
                            <ul className='d-flex gap-3 align-items-center p-0'>
                                <li>
                                    <Link to='/' className="nav-link upper-case"><img src={logo} alt="" className="logo-img" /></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 text-center"></div>
                    <div className="col-md-4 text-end mt-2 d-flex align-items-center justify-content-end">
                        <Button variant="outlined" color="error" className=' guid-btn me-4 button-set-1'><Link className='set-link-1' to="/guide">Guide</Link></Button>
                        <div class="dropdown w-100">
                            <Button variant="outlined"
                                color='primary' className="w-100 dropdown-toggle justify-content-between" data-bs-toggle="dropdown" aria-expanded="false">
                                {localStorage.getItem("email").split("@gmail.com")}
                            </Button>
                            <ul class="dropdown-menu">
                                <li><Link className="dropdown-item text-dark border-bottom" to='/change-password'>Change Password</Link></li>
                                <li><Link
                                    onClick={() => {
                                        handleClose()
                                        localStorage.clear();
                                        window.location.href = '/'
                                    }}
                                    className="dropdown-item text-dark " >Logout</Link></li>

                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Header