import React, { useState, useEffect } from 'react';
import {
  ComposedChart,
  Tooltip,
  CartesianGrid,
  Legend,
  Area,
  Bar,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';

const config = require('../Helpers/config.json');

const UserChart = (props) => {
  const [data1, setData1] = useState([]);
  const [chartData, setChartData] = useState([]); 

  useEffect(() => {
    fetch(`${config['baseUrl']}/get-stats?uid=ea7df408eb5ec8c4a13d6acaf9b622c6`, {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setData1(res.data);

        
        const mappedChartData = res.transactions.map((transaction) => ({
          name: transaction.date,
          uv: transaction.total_transaction_amount,
          pv: transaction.total_transactions,
          amt: transaction.total_transaction_amount,
        }));

        setChartData(mappedChartData);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);




  const [data, setData] = useState({
        total_transactions: 0,
        total_transaction_amount: 0,
        total_users: 0,
        active_packages: 0,
      });
    
      useEffect(() => {
        fetch(`${config['baseUrl']}/get-stats?uid=ea7df408eb5ec8c4a13d6acaf9b622c6`, {
          method: 'GET',
          headers: { 'content-type': 'application/json' },
        })
          .then((res) => res.json())
          .then((res) => {
            console.log(res);
            setData(res);
          })
          .catch((error) => {
            console.error(error);
          });
      }, []);





  return (
    <>
      <div className="col-12">
        <div className="OneMonSalesProgress">
          <div className="OneMonSalesProgressHead">
            {/* <span>
              {props.NewUsers} {props.Transaction}
            </span>
            <h5>
              {props.NewUsers} {props.Transaction}
            </h5> */}
            <div className="OneMonInnerBox">
              <span>Sale Till Now ${data.total_transaction_amount}</span>
              <span>Sales Last Year Some Month ${chartData.length > 0 ? chartData[chartData.length - 1].amt : 0}</span>
            </div>
          </div>
          <div className="OneMonsalesProgressChartBox">
            <ResponsiveContainer className={"OneMonsalesProgressChart"} width="100%" aspect={3.6}>
              <ComposedChart data={chartData}> {/* Use chartData instead of data */}
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <CartesianGrid stroke="#f5f5f5" />
                <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" />
                <Bar dataKey="pv" barSize={20} fill="#413ea0" />
                <Line type="monotone" dataKey="uv" stroke="red" />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserChart;
