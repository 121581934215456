import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar/Sidebar'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Header from '../Header/Header';
import Pagination from '@mui/material/Pagination';
import axios from 'axios';

import config from '../Helpers/config.json';

const Transaction = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        axios.get(`${config['baseUrl']}/transactions?page=${currentPage}`, {
            headers: { uid: 'ea7df408eb5ec8c4a13d6acaf9b622c6' }
        })
        .then(res => {
            const data = res.data;
            if (data.success && data.subscriptions) {
                setUsers(data.subscriptions);
                setTotalPages(data.pagination.totalPages);
            }
            setLoading(false);
        })
        .catch(error => {
            setError(error);
            setLoading(false);
        });
    }, [currentPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };



    return (
        <>
            <Header />
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-3 p-0">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Transactions</h1>
                            </div>
                        </div>
                        {loading ? (
                            <p>Loading...</p>
                        ) : error ? (
                            <p>Error: {error.message}</p>
                        ) : (
                            <>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Customer name</TableCell>
                                                <TableCell align="center">Package name</TableCell>
                                                <TableCell align="center">Purchase amount</TableCell>
                                                <TableCell align="center">Date bought</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {users.map(data => (
                                                <TableRow
                                                    key={data.subscription_id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="center">
                                                        <span className="new"></span> {data.customer_name}
                                                    </TableCell>
                                                    <TableCell align="center">{data.package_name}</TableCell>
                                                    <TableCell align="center">${data.purchase_amount}</TableCell>
                                                    <TableCell align="center">{data.purchased_at}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <div className='set-pagination-div-1'>
                                <Pagination
    count={totalPages}
    page={currentPage}
    variant="outlined"
    shape="rounded"
    onChange={(event, page) => handlePageChange(page)}
/>
                                </div>



                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Transaction;
