import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ChangePassword from '../Components/ChangePassword/ChangePassword'
import Login from '../Components/Login/Login'
import Packages from '../Components/Package/Packages'
import Transaction from '../Components/Transaction/Transaction'
import User from '../Components/User/User'
import Guide from '../Components/Guide/guide'
import Dashboard from '../Components/Dashboard/Dashboard'
import TransactionChart from '../Components/TransactionChart/TransactionChart'
import DailyProductChart from '../Components/DailyProductChart/DailyProductChart'
import DailyUserUsage from '../Components/DailyUserUsage/DailyUserUsage'
const Routing = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    {
                        localStorage.getItem("email") === null || localStorage.getItem("email") === undefined || localStorage.getItem("email") === "" ?
                            <Route path='/' element={<Login />} />
                            :
                            <>
                                <Route path='/' element={<Dashboard />} />
                                <Route path='/user' element={<User />} />
                                <Route path='/guide' element={<Guide />} />
                                <Route path='/transactionchart' element={<TransactionChart />} />
                                <Route path='/dailyproductchart' element={<DailyProductChart />} />
                                <Route path='/dailyuserchart' element={<DailyUserUsage />} />
                                <Route path='/transaction' element={<Transaction />} />
                                <Route path='/package' element={<Packages />} />
                                <Route path='/change-password' element={<ChangePassword />} />
                            </>
                    }
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default Routing