import React from 'react'
import Header from '../Header/Header'
import Sidebar from '../SideBar/Sidebar'
import { Link, NavLink } from 'react-router-dom'
import UserChart from '../UserChart/UserChart'
import ChartBox from '../ChartBox/ChartBox'
const TransactionChart = () => {
    const data = [
        {
            name: 'Page A',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];
    return (
        <>
            <Header />
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-3 p-0">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <ChartBox />
                        <div className="row">
                            <div className="col-md-12">
                                <UserChart Transaction=" Today's transactions" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TransactionChart