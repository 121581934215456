import logo from './logo.svg';
import './App.css';
import Routing from './Routing/Routing';

function App() {
  return (
    <>
      <Routing />
    </>
  );
}

export default App;
