import React from 'react'
import Header from '../Header/Header'
import { Button } from '@mui/material'
import Sidebar from '../SideBar/Sidebar'
const ChangePassword = () => {
    return (
        <>
            <Header />
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-md-3 p-0 mt-5">
                        <Sidebar />
                    </div>
                    <div className="col-md-9 mt-5 rounded-1 p-4 ">
                        <div className="row mb-4 ">
                            <div className="col-md-12">
                                <span className='text-dark'><b>Current Password</b></span>
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <span className='text-dark'><b>New Password</b></span>
                                <input type="password" className="form-control" />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <span className='text-dark'><b>Confirm Password</b></span>
                                <input type="password" className="form-control" />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12 text-end">
                                <Button variant="outlined" color="primary" className='me-3'>Save changes</Button>
                                <Button variant="outlined" color="warning">Reset</Button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ChangePassword