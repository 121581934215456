import React from 'react'
import Header from '../Header/Header'
import Sidebar from '../SideBar/Sidebar'
import { Link, NavLink } from 'react-router-dom'
import UserChart from '../UserChart/UserChart'
import { Chart } from "react-google-charts";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ChartBox from '../ChartBox/ChartBox'
const DailyUserUsage = () => {
    const data = [
        ["Element", "Density", { role: "style" }],
        ["Trigger 1", 8.94, "#b87333"], // RGB value
        ["Trigger 2", 10.49, "silver"], // English color name
        ["Trigger 3", 19.3, "gold"],
        ["Trigger 4", 21.45, "color: #e5e4e2"], // CSS-style declaration
    ];
    return (
        <>
            <Header />
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-3 p-0">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <ChartBox />
                        <div className="row mt-5 rounded ">
                            <div className="col-12">
                                <div className="OneMonSalesProgress">
                                    <div className="OneMonSalesProgressHead">
                                        <span> Daily User Usage</span>
                                        <h5> Daily User Usage</h5>
                                        {/* <div className="OneMonInnerBox">
                                            <span>Sale Till Now $35</span>
                                            <span>Usage Last Year Some Month : $2545</span>
                                        </div> */}
                                    </div>
                                    <div className="OneMonsalesProgressChartBox">
                                        <Chart chartType="ColumnChart" width="100%" height="400px" data={data} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default DailyUserUsage