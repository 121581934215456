import React, {useState, useEffect} from 'react';
import './guide.css'
import Sidebar from '../SideBar/Sidebar';
import Header from '../Header/Header';
import Button from '@mui/material/Button';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import config from '../Helpers/config.json'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
};
const Guide = () => {

    

 


    const [data, setdata] = useState('');

    const handleChange = (event, editor) => {
      const data = editor.getData();
      console.log({ event, editor, data });
      setdata(data);
    };
    const [check, setcheck] = useState(null)
    const [description, setdescription] = useState("");
  
    useEffect(() => {
      fetch(`${config['baseUrl']}/guide`, {
        method: "GET",
        headers: { 'content-type': 'application/json' }
      }).then(res => {
        return res.json()
      }).then(res => {
        console.log(res.message, "data")
        // if (res.length > 0) {
          setdata(res.message)
          setcheck(res.data)
          
        // }
      })
  
  
    }, [])
    const Update = () => {
  
      fetch(`${config['baseUrl']}/guide`, {
        method: "POST",
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(
            {
                "content": data
            }
        )
      }).then(res => {
        return res.json()
      }).then(res => {
        window.location.reload(true)
      })
    }
    


    
    
    return (
        <>
            <Header />
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-3 p-0">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-6">
                                <h1>Guide</h1>
                            </div>
                        </div>
                        {
            // check ?

            <CKEditor
      editor={ClassicEditor}
      data={data}
      onReady={(editor) => {
        console.log('CKEditor5 React Component is ready to use!', editor);
      }}
      onChange={handleChange}
    />
            //   : <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            //     <CircularProgress />
            //   </Box>
          }
                       
                        <div className="text-end">
                            <Button
                                variant="outlined"
                                color="primary"
                                className="mt-4"
                                onClick={Update}
                            >
                                Save
                            </Button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Guide