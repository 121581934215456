import { React, useState } from 'react'
import { Link } from 'react-router-dom';
import google from '../../img/icons8-google-26.png'
const config = require('../Helpers/config.json');


const Login = () => {
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [tab1, settab1] = useState(true);
    const [showEmailError, setshowEmailError] = useState(false);
    const [showPasswordError, setshowPasswordError] = useState(false);
    const [showPassword, setShowPassword] = useState(true)
    const login =async () => {
        if (email === "" ) {
            setshowEmailError(true)
        }
        if (password === "") {
            setshowPasswordError(true)
        }
        else  {
            await fetch(`${config['baseUrl']}/AdminLogin`, {
                method: "POST",
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({
                        "email":email,
                        "password":password
                })
            }).then(res => {
                return res.json()
            }).then(res => {
                console.log(res)
           if(res.message=="successfully login"){
            localStorage.setItem("email", email);
            setshowEmailError(false)
            setshowPasswordError(false)
            window.location.reload(true);
           }
           else{
            alert("failed")
           }
            })
        }

    }
    return (
        <>
            <div className="container mt-5  border rounded-1 box-login-shadow login-container bg-white p-4">
                <div className="row border-bottom pb-3">
                    <div className="col-md-12 ">
                        <div className="tab-heading d-flex align-items-center justify-content-center gap-5">
                            <h5 className={tab1 === true ? "text-dark cursor fw-normal tab-active" : " text-dark cursor fw-normal"}>Login</h5>

                        </div>

                    </div>
                </div>
                <div className="row mt-5 ">
                    <div className="col-md-12 text-center">
                        <div className={tab1 === true ? "login-tab" : "d-none"}>
                            <div className="row">
                                <div className="col-md-12 text-start">
                                    <span className='text-dark'>Email</span>
                                    <input
                                        onChange={(e) => setemail(e.target.value)}
                                        type="email" className="form-control w-100 p-2 rounded-1 bg-transparent" />
                                    {
                                        showEmailError === true ?
                                            <span className="text-danger opacity-1" style={{ transition: ".4s all ease", transitionDelay: "1s" }}>
                                                Please enter Correct email address
                                            </span>
                                            :
                                            <span className="text-danger opacity-0" style={{ transition: ".4s all ease" }}>
                                                Please enter Correct email address
                                            </span>

                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-start">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <span className='text-dark'>Password</span>
                                        </div>
                                        <div className="col-md-6 text-end">
                                            {
                                                showPassword === true ?
                                                    <span
                                                        onClick={() => setShowPassword(false)}
                                                        className="eye-closed cursor">
                                                        <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94" />
                                                            <path d="M14.12 14.12a3 3 0 1 1-4.24-4.24" />
                                                            <path d="m1 1 22 22" />
                                                            <path d="M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19" />
                                                        </svg>
                                                    </span>
                                                    :
                                                    <span
                                                        onClick={() => setShowPassword(true)}
                                                        className="eye cursor">
                                                        <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                                                            <path d="M12 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6z" />
                                                        </svg>
                                                    </span>
                                            }
                                        </div>
                                    </div>
                                    <input
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                login()
                                            }
                                        }}
                                        onChange={(e) => setpassword(e.target.value)}
                                        type={showPassword === true ? "password" : "text"} className="form-control w-100 p-2 rounded-1 bg-transparent" />
                                    {
                                        showPasswordError === true ?
                                            <span className="text-danger opacity-1" style={{ transition: ".4s all ease" }}>
                                                Please enter correct password
                                            </span>
                                            :
                                            <span className="text-danger opacity-0" style={{ transition: ".4s all ease" }}>
                                                Please enter correct password
                                            </span>
                                    }

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <button
                                        onClick={login}
                                        className="login-btn p-1 mt-2 w-100 rounded-1 text-white">
                                        Log In
                                    </button>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <p>Forgot Password? <Link to='/'>Reset it</Link></p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Login
