import React from 'react'
import Header from '../Header/Header'
import Sidebar from '../SideBar/Sidebar'
import { Link, NavLink } from 'react-router-dom'
import UserChart from '../UserChart/UserChart'
import { Chart } from "react-google-charts";
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import ChartBox from '../ChartBox/ChartBox'
const DailyProductChart = () => {
    const data = [
        ["day", "a", "b", "c", "d"],
        ["Mon", 20, 28, 38, 45],
        ["Tue", 31, 38, 55, 66],
        ["Wed", 50, 55, 77, 80],
        ["Thu", 50, 77, 66, 77],
        ["Fri", 15, 66, 22, 68],
    ];
    return (
        <>
            <Header />
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-3 p-0">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        {/* <div className="row">

                            <div className="col-md-3">
                                <NavLink to='/' className='text-dark tdn'>
                                    <div className="dashboard-box bg-white  py-4 px-2 rounded-2 box-login-shadow cursor">
                                        <span className="me-2">
                                            <svg width={30} height={30} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.75 9.5a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
                                                <path fillRule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm10-8.75a8.75 8.75 0 0 0-6.835 14.212C6.053 16.032 8.006 14.5 12 14.5c3.994 0 5.946 1.531 6.835 2.962A8.75 8.75 0 0 0 12 3.25Z" clipRule="evenodd" />
                                            </svg>
                                        </span>
                                        New users
                                        <div className="row pt-1 mt-1">
                                            <div className="col-md-4 ">
                                                <span className='border-dotted'><b>455</b></span>
                                            </div>
                                            <div className="col-md-4">
                                            </div>

                                            <div className="col-md-4">
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>

                            </div>
                            <div className="col-md-3">
                                <NavLink to='/transactionchart' className='text-dark tdn'>
                                    <div className="dashboard-box bg-white py-4 px-2 rounded-2 box-login-shadow cursor">

                                        <span className="me-2">
                                            <svg width={30} height={30} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.4 5.76a2.4 2.4 0 0 1 2.4-2.4H21a.6.6 0 1 1 0 1.2h-.6v2.4a1.2 1.2 0 0 1 1.2 1.2v10.2a1.8 1.8 0 0 1-1.8 1.8H5.4a3 3 0 0 1-3-3V5.76Zm1.2 2.078v9.321a1.8 1.8 0 0 0 1.8 1.8h14.4a.6.6 0 0 0 .6-.6V8.16H4.8c-.421 0-.835-.11-1.2-.321Zm0-2.079a1.2 1.2 0 0 0 1.2 1.2h14.4v-2.4H4.8a1.2 1.2 0 0 0-1.2 1.2Z" />
                                            </svg>
                                        </span>
                                        Today's transactions
                                        <div className="row pt-1 mt-1">
                                            <div className="col-md-4 ">
                                                <span className='border-dotted'><b>111</b></span>
                                            </div>
                                            <div className="col-md-4">
                                            </div>
                                            <div className="col-md-4">
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>

                            </div>
                            <div className="col-md-3 ">
                                <NavLink to='/dailyproductchart' className='text-dark tdn'>
                                    <div className="dashboard-box bg-white py-4 px-2 rounded-2 box-login-shadow cursor">
                                        <span className="me-2">
                                            <svg width={30} height={30} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M14.76 6.6a3 3 0 0 0-6 0v.6h6v-.6Zm1.2 0v.6h4.2v12a2.4 2.4 0 0 1-2.4 2.4h-12a2.4 2.4 0 0 1-2.4-2.4v-12h4.2v-.6a4.2 4.2 0 0 1 8.4 0Zm-.775 6.425a.6.6 0 1 0-.85-.85l-3.175 3.177-1.375-1.376a.602.602 0 0 0-1.025.424.6.6 0 0 0 .176.425l1.8 1.8a.6.6 0 0 0 .85 0l3.6-3.6Z" clipRule="evenodd" />
                                            </svg>
                                        </span>
                                        Daily Trigger
                                        <div className="row pt-1 mt-1">
                                            <div className="col-md-4">
                                                <span className=' border-dotted'><b>421</b></span>
                                            </div>
                                            <div className="col-md-4">
                                            </div>
                                            <div className="col-md-4">
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>

                            </div>
                            <div className="col-md-3">
                                <NavLink to='/dailyuserchart' className='text-dark tdn'>
                                    <div className="dashboard-box bg-white py-4 px-2 rounded-2 box-login-shadow cursor">
                                        <span className="me-2">
                                            <svg width={30} height={30} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.8 19.2s-1.2 0-1.2-1.2 1.2-4.8 6-4.8 6 3.6 6 4.8c0 1.2-1.2 1.2-1.2 1.2h-9.6Zm4.8-7.2a3.6 3.6 0 1 0 0-7.2 3.6 3.6 0 0 0 0 7.2Z" />
                                                <path fillRule="evenodd" d="M8.66 19.2A2.685 2.685 0 0 1 8.4 18c0-1.626.816-3.3 2.324-4.464A7.592 7.592 0 0 0 8.4 13.2c-4.8 0-6 3.6-6 4.8 0 1.2 1.2 1.2 1.2 1.2h5.06Z" clipRule="evenodd" />
                                                <path d="M7.8 12a3 3 0 1 0 .001-6 3 3 0 0 0 0 6Z" />
                                            </svg>
                                        </span>
                                        Daily User Usage
                                        <div className="row pt-1 mt-1">
                                            <div className="col-md-4">
                                                <span className='border-dotted'><b>422</b></span>
                                            </div>
                                            <div className="col-md-4">
                                            </div>
                                            <div className="col-md-4">
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>

                            </div>
                        </div> */}
                        <ChartBox />
                        <div className="row mt-5 rounded bg-transparent">
                            <div className="col-12">
                                <div className="OneMonSalesProgress">
                                    <div className="OneMonSalesProgressHead">
                                        <span> Daily Trigger</span>
                                        <h5> Daily Trigger</h5>
                                    </div>
                                    {/* =================================================== */}

                                    <div className="OneMonsalesProgressChartBox">
                                        <Chart
                                            chartType="CandlestickChart"
                                            width="100%"
                                            height="400px"
                                            data={data}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DailyProductChart