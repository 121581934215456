import React, { useState,useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import Sidebar from '../SideBar/Sidebar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Header from '../Header/Header';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import Modal from '@mui/material/Modal';
import axios from 'axios';

import config from '../Helpers/config.json';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
};
const User = () => {


  const [checkname,setcheckname]=useState("")
  const [checknumber,setchecknumber]=useState("")
  const [name,setname]=useState("")
  const [number,setnumber]=useState("")
  const [email,setemail]=useState("")
  const [password,setpassword]=useState("")
  const [confirmpassword,setconfirmpassword]=useState("")

  const [UpdateState,setUpdateState]=useState()

  const [id,setid]=useState("")

  const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleOpen1 = (userId) => {
        setSelectedUserId(userId);
        const userToUpdate = users.find((user) => user.id === userId);
        if (userToUpdate) {
            setname(userToUpdate.name);
            setcheckname(userToUpdate.name);
            setchecknumber(userToUpdate.number);
            setnumber(userToUpdate.number);
            setemail(userToUpdate.email);
            setpassword(userToUpdate.password);
            setconfirmpassword(userToUpdate.confirmpassword);
        }
        setOpen1(true);
    };
    const handleClose = () => setOpen(false);
    const handleClose1 = () => setOpen1(false);

    const [selectedUserId, setSelectedUserId] = useState(null);

     const [users, setUsers] = useState([]);
    useEffect(() => {
        axios.get(`${config['baseUrl']}/users?page=${currentPage}`, {
            headers: {uid: 'ea7df408eb5ec8c4a13d6acaf9b622c6'}
        }).then((res) => {
            res = res.data;

            if (res.users.subscriptions) {
              setUsers(res.users.subscriptions);
              setTotalPages(res.users.pagination.totalPages);
            }
            if (res.users?.length) {
              setUsers(res.users);
            }
          });
      }, [currentPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };


    
    

    const createUser = async () => {
        const uid = 'ea7df408eb5ec8c4a13d6acaf9b622c6';
        
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'uid': uid,
          },
          body: JSON.stringify({
            name: name,
            number: number,
            email: email,
            password: password,
            packageId: null
          }),
        };
      
        try {
          const response = await fetch(
            `${config['baseUrl']}/users`,
            requestOptions
          );
          const data = await response.json();
          console.log('User created:', data);
        //    alert(data.message)
    // alert(data.errors[0].msg);
    
    
      if (response.ok) {
        toast.success(`${data.message}`, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          window.location.reload(true);
        }
        else if(name == ""){
            toast.error(`Name is required`, {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          }
      else if(number == ""){
        toast.error(`Number is required`, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
      }
      else if(email == ""){
        toast.error(`Email is required`, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
      }
      else if(password == ""){
        toast.error(`Password is required`, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
      }
      else if(confirmpassword == ""){
        toast.error(`Cornfirm Password is required`, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
      }
      else if(password != confirmpassword){
        toast.error(`Passwords dont match`, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
      }
      else{
        toast.error(`${data.errors[0].msg}`, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
      }


        //   if (response.ok) {
        //     const data = await response.json();
        //     console.log('User created:', data);
           
        //   } else {
        //     console.error('Failed to create user');
        //   }
        } catch (error) {
          console.error('Error:', error);
        }
      };


    
    
      const updateUser = async () => {


        if(name != checkname || number != checknumber){
            if (selectedUserId === null) {
                return;
              }
          
              const updatedUser = {
                name: name,
                number: number,
                email: email,
                password: password,
              };
          
              try {
                const response = await axios.put(
                  `${config['baseUrl']}/users/${selectedUserId}`,
                  updatedUser,
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      uid: 'ea7df408eb5ec8c4a13d6acaf9b622c6',
                    },
                  }
                );
      
                const message = response.data.message;
                if (message == "User updated successfully") {
                  toast.success('User updated successfully', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  window.location.reload(true);
                  setOpen1(false);
                  
                  setname('');
                  setnumber('');
                  setemail('');
                  setpassword('');
                  setconfirmpassword('');
          
                } else {
                  toast.error('Failed to update user', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
              
              } catch (error) {
                console.error('Error:', error);
              }
        }else{

            toast.error('Chnage at least one field', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
    }

      };



      const deleteuser = async (id) => {
        const uid = 'ea7df408eb5ec8c4a13d6acaf9b622c6';
    
        try {
            const response = await fetch(`${config['baseUrl']}/users/${id}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'uid': uid,
                },
                body: JSON.stringify({ "id": id }),
            });
    
            if (response.ok) {
                const data = await response.json();
                if (data.message === "User deleted successfully") {
                    toast.success(`User deleted successfully.`, {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    window.location.reload(false);
                } else {
                    toast.error(`Failed to delete the user.`, {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                }
            } else {
                toast.error(`Failed to delete the user. Server returned an error.`, {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
            }
        } catch (error) {
            toast.error(`An error occurred while deleting the user.`, {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            console.error(error);
        }
    };
      


    const deleteuserconfirm = (id)=> {

        Swal.fire({
            title: 'Are you sure you want to delete?',
            // text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#4BB543',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire(
                'Deleted!',
                'user has been deleted.',
                'success'
              );
              deleteuser(id);
            }
          })
    }


   
   
      
  
   




    const exportCSV = async () => {
        let allUsers = [];
        let currentPage = 1;
      
        while (true) {
          const headers = {
            uid: 'ea7df408eb5ec8c4a13d6acaf9b622c6',
          };
      
          const response = await fetch(`${config['baseUrl']}/users?page=${currentPage}`, {
            headers: headers,
          });
      
          const data = await response.json();
          const users = data.users.subscriptions;
      
          if (users.length === 0) {
            break;
          }
      
          allUsers = allUsers.concat(users);
          currentPage++;
        }
      
        const headers = 'Name,Email,Number\n';
        const usersCSV = allUsers.map((user) => {
          return `${user.name},${user.email},${user.number}`;
        }).join('\n');
      
        const finalCSV = headers + usersCSV;
      
        const blob = new Blob([finalCSV], { type: 'text/csv' });
      
        const url = window.URL.createObjectURL(blob);
      
        const a = document.createElement('a');
        a.href = url;
        a.download = 'users.csv';
      
        a.click();
      
        window.URL.revokeObjectURL(url);
      };
      





      
 



    return (
        <>









<ToastContainer />

<Modal
                                    open={open1}
                                    onClose={handleClose1}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <div className="container">
                                            <div className="row border-bottom mb-4">
                                                <div className="col-md-6">
                                                    <h5 className=''>Update User</h5>
                                                </div>
                                                <div className="col-md-6 text-end">
                                                    <span onClick={handleClose1} className="cross cursor">
                                                        <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M18 6 6 18" />
                                                            <path d="m6 6 12 12" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Name:</span>
                                                    <input value={name}
                  onChange={(e) => setname(e.target.value)}  placeholder='Enter Name' type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Number:</span>
                                                    <input value={number}
                  onChange={(e) => setnumber(e.target.value)} placeholder='Enter Number' type="number" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Email:</span>
                                                    <input value={email}
                  onChange={(e) => setemail(e.target.value)} placeholder='Enter Email' type="email" className="form-control" disabled/>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                <Alert className='set-alert-1' severity="error">Enter password if you want to update.</Alert>
                                                    <span>New password:</span>
                                                    <input value={password}
                  onChange={(e) => setpassword(e.target.value)} placeholder='' type="password" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Confirm Password:</span>
                                                    <input value={confirmpassword}
                  onChange={(e) => setconfirmpassword(e.target.value)} placeholder='' type="password" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <Button variant="outlined" color="success" className='w-100' onClick={updateUser}>Update</Button>
                                                </div>
                                            </div>

                                        </div>
                                    </Box>
                                </Modal>


            <Header />
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-3 p-0">
                        <Sidebar />
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-6">
                                <h1>Users</h1>
                            </div>
                            <div className="col-md-6 text-end">
                                <Button className="me-3 export-csv-btn-1" variant="outlined" color="success" onClick={exportCSV}> Export CSV</Button>
                                <Button onClick={handleOpen} variant="outlined" color="primary"> Create User</Button>
                                {/* Create Modal */}
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <div className="container">
                                            <div className="row border-bottom mb-4">
                                                <div className="col-md-6">
                                                    <h5 className=''>Create User</h5>
                                                </div>
                                                <div className="col-md-6 text-end">
                                                    <span onClick={handleClose} className="cross cursor">
                                                        <svg width={17} height={17} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M18 6 6 18" />
                                                            <path d="m6 6 12 12" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Name:</span>
                                                    <input placeholder='Enter name...' type="text" className="form-control" onChange={(e) => setname(e.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Number:</span>
                                                    <input placeholder='Enter Number...' type="number" className="form-control" onChange={(e) => setnumber(e.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Email:</span>
                                                    <input placeholder='Enter Email...' type="email" className="form-control" onChange={(e) => setemail(e.target.value)}/>
                                                   
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Password:</span>
                                                    <input placeholder='Enter Password...' type="password" className="form-control" onChange={(e) => setpassword(e.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <span>Confirm Password:</span>
                                                    <input placeholder='Enter Confirm Password...' type="password" className="form-control" onChange={(e) => setconfirmpassword(e.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    <Button variant="outlined" color="success" className='w-100'  onClick={createUser}>Create</Button>
                                                </div>
                                            </div>

                                        </div>
                                    </Box>
                                </Modal>
                            </div>
                        </div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">
                                            Name
                                        </TableCell>
                                        <TableCell align="center">Number</TableCell>
                                        <TableCell align="center">Email</TableCell>
                                        <TableCell align="center">Edit</TableCell>
                                        <TableCell align="center">Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                                users.map(user => (
                                                    <>
                                <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >

                                        <TableCell align="center">
                                            <span className="new"></span> {user.name}</TableCell>
                                        <TableCell align="center">{user.number}</TableCell>
                                        <TableCell align="center">{user.email}</TableCell>
                                        <TableCell align="center">
                                            <button className='edit-button' onClick={() => handleOpen1(user.id)}>
                                            <span className="edit-btn text-warning">
                                                <svg width={17} height={17} fill="none" stroke="CurrentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 20h9" />
                                                    <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" />
                                                </svg></span>
                                            </button>

                                        

                                             </TableCell>
                                        <TableCell align="center">
                                        <button className='edit-button' onClick={() => deleteuserconfirm(user.id)}>
    <span className="delete-btn text-danger">
        <svg width={17} height={17} fill="none" stroke="CurrentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 6h18" />
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
        </svg>
    </span>
</button>
                                        </TableCell>
                                    </TableRow>




</>

                                        ))
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>


                        <div className='set-pagination-div-1'>
                                <Pagination
    count={totalPages}
    page={currentPage}
    variant="outlined"
    shape="rounded"
    onChange={(event, page) => handlePageChange(page)}
/>
                                </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default User